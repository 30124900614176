<template>
	<div class="documents-dashboard">
		<DocumentsHeader
			v-if="marks"
			:activeRole="activeRoleName"
			:marks="marks"
			:showSettings="showSettings"
			:isExpanded="isExpanded"
			:showMerge="isCheckedInOneCategory"
			@changeMark="changeMarkChecked"
			@uncheckAllMarks="uncheckAllMarks"
			@expand="expand"
			@removeChecked="removeChecked"
			@deleteChecked="openDeleteContext"
			@merge="mergeToSubcategory"
		/>
		<Preloader :loading="isLoading" />

		<template v-if="!isLoading">
			<template v-if="categories && categories.length">
				<Columns :columns="$mq | mq({ xs: 1, sm: 1, md: 2, lg: 3, xl: 4 })">
					<template
						v-for="(item, index) in categories"
					>
						<DndCard
							v-if="(item.knowledges && item.knowledges.length) || ((item.subcategories && item.subcategories.length) && item.subcategories.some(el => el.knowledges.length))"
							:key="index"
							:item="item"
							:index="index"
							:user-can-edit="userCanEdit"
							:is-expanded="isExpanded"
							@deleteCategory="deleteCategory"
							@editPinState="editPinState"
							@updateCategoryName="updateCategoryName"
							@deleteItem="deleteItem"
							@onDrop="onDrop"
							@onSubcategoryDrop="onSubcategoryDrop"
							@deleteSubcategoryItem="deleteSubcategoryItem"
							@deleteSubcategory="deleteSubcategory"
							@removeExpand="isExpanded = false"
							@checkItem="checkItem"
						/>
					</template>
				</Columns>
			</template>
			<EmptyBackplate
				v-else
				to="/documents/create"
				message="Еще не добавлено ни одного знания"
			/>
		</template>

		<DeleteContext v-if="showDeleteContext" :center="true" @close="closeDeleteContext" @delete="deleteChecked">
			<h3 class="documents-dashboard__delete-items-title">Удалить выбранные знания ({{ deleteItemsLength }})?</h3>
			<p class="documents-dashboard__delete-items-info">{{ deleteItemsTitles }}</p>
		</DeleteContext>

		<Editor
            v-if="showEditor"
            :valueLength="30"
            :header="$tc('Documents.Document.Meta.NewSubCategory')"
            :value="{ name: '' }"
			@submit="createSubCategory"
            @cancel="closeEditor"
        />

		<div v-if="showCreateSub" class="subcategory-popup" @click.stop="showCreateSub = false">
			<div class="subcategory-popup__content" @click.stop>
				<h3 class="subcategory-popup__content-title">Объединить в категорию</h3>
				<div class="subcategory-popup__content-items">
					<template v-if="subcategoriesArray && subcategoriesArray.length">
						<span
							v-for="(el, i) in subcategoriesArray"
							:key="i"
							class="subcategory-popup__content-item"
							@click="groupSubcategory(el.id, el.parent_id)"
						>
							{{ el.name }}
						</span>
					</template>
					<span v-else class="subcategory-popup__content-empty">
						категорий не найдено.
					</span>
				</div>
				<div class="subcategory-popup__content-button" @click.stop="openEditor">
					<Icon name="plus" size="14" />
					<span>Новая категория</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import modules
import KnowledgeModule     from '@/store/modules/knowledge/KnowledgeModule';
import TagsModule          from '@/store/modules/tags/TagsModule';
import SubCategoriesModule from '@/store/modules/subcategories/SubCategoriesModule';

// import usable functional
import Notify      from '@/services/helpers/notify';
import UsersModule from '@/store/modules/users/UsersModule';
import ApiClient   from '@/api/api.client';

// import components
import Icon            from '@/components/ui/icon/Icon.vue';
import DocumentsHeader from '@/components/documents/documentsDashboardHeader';
import DeleteContext   from '@/components/context-menu/repeatable/DeleteContext.vue';
import Editor          from '@/modules/settings/views/directions/components/Editor.vue';
import DndCard         from '@/components/documents/dashboard-item/dndCard.vue';
import EmptyBackplate  from '@/components/app-bar/EmptyBackplate.vue';
import Preloader       from '@/components/preloader/PreloaderWrap.vue';
import { Columns }     from 'vue-columns';

export default {
	name: 'DocumentsDashboardRole',

	data()
	{
		return {
			categories        : null,
			marks             : null,
			isLoading         : true,
			activeRoleName    : null,
			showSettings      : true,
			isExpanded        : false,
			showDeleteContext : false,
			deleteItemsLength : null,
			deleteItemsTitles : '',
			showCreateSub     : false,
			subcategoriesArray: false,
			showEditor        : false,
		}
	},

	components: {
		Columns,
		DocumentsHeader,
		DeleteContext,
		EmptyBackplate,
		Preloader,
		DndCard,
		Icon,
		Editor,
	},

	computed:
	{
		userCanEdit() { return UsersModule.currentUser.admin || UsersModule.currentUser.permitions.knowledge.manage; },
		isCheckedInOneCategory() {
			let checkedCategoriesCount = 0;

			this.categories.forEach(category => {
				let hasCheckedInCategory = false;

				// Проверка знаний в самой категории
				const categoryHasChecked = category.knowledges && category.knowledges.some(knowledge => knowledge.checked === true);

				// Проверка знаний в подкатегориях
				const subcategoriesHaveChecked = category.subcategories && category.subcategories.some(subcategory => {
					return subcategory.knowledges && subcategory.knowledges.some(knowledge => knowledge.checked === true);
				});

				// Если либо в категории, либо в её подкатегориях есть отмеченные элементы
				if (categoryHasChecked || subcategoriesHaveChecked) {
					hasCheckedInCategory = true;
				}

				// Увеличиваем счетчик, если есть отмеченные элементы в категории или её подкатегориях
				if (hasCheckedInCategory) {
					checkedCategoriesCount++;
				}
			});

			// Возвращаем true, если отмеченные элементы есть только в одной категории или её подкатегориях
			return checkedCategoriesCount === 1;
		},
	},

	methods:
	{
		async createSubCategory(value) {
			const data = this.findCheckedItems();

			const subName = value.name;
			let categoryId = '';

			if (data.categories && data.categories.length)
				categoryId = data.categories[0].categoryId;

			if (data.subcategories && data.subcategories.length)
				categoryId = data.subcategories[0].categoryId;

			const subCat = { parentId: categoryId, name: subName };

			await SubCategoriesModule.fetchSubCreateCategory(subCat)
				.then(async (res) => {
					await this.groupSubcategory(res.data.id, res.data.parent_id);
					this.closeEditor();
					Notify.success('Успешно!');
				})
				.catch(() => {
					this.closeEditor();
					Notify.success('Произошла ошибка');
				})
		},

		async groupSubcategory(subId, catId) {
			const string = this.collectIds();
			this.showCreateSub = false;

			const obj = {
				category_id: catId,
				role: +this.$route.params.id,
				subcategory_id: subId,
				ids: string,
			};

			await SubCategoriesModule.groupSubcategory(obj)
				.then(async () => {
					await this.fetchRole();
				})
		},

		closeEditor() {
			this.showEditor = false;
		},

		openEditor() {
			this.showEditor = true;
			this.showCreateSub = false;
		},

		expand(value)
		{
			this.isExpanded = value;
		},

		changeMarkChecked(markId)
		{
			const idx = this.marks.findIndex(el => el.id === markId);
			this.marks[idx].checked = !this.marks[idx].checked;

			// fetching knowledges with filter
			clearInterval(this.filterTimeout);
			this.filterTimeout = setTimeout(() => {
				let filter = '';

				this.marks.forEach((el, i) => {
					if (el.checked)
						filter = filter + `&filter[tags][${i}]=` + el.id;
				});

				this.fetchRole(filter);
			}, 1000);
		},

		uncheckAllMarks()
		{
			this.marks.forEach(el => el.checked = false);
			this.fetchRole();
		},

		addChecked(obj)
		{
			if (obj.knowledges) {
				obj.knowledges.forEach(item => {
					item.checked = false;
				});
			}

			if (obj.subcategories) {
				obj.subcategories.forEach(subcategory => {
					this.addChecked(subcategory);
				});
			}
		},

		findCheckedItems()
		{
			const result = {
				categories: [],
				subcategories: [],
			};

			this.categories.forEach((category, categoryIdx) => {
				// Поиск в категории
				if (category.knowledges)
				{
					category.knowledges.forEach((knowledge) => {
						if (knowledge.checked === true)
						{
							result.categories.push({
								categoryId: category.id,
								knowledgeId: knowledge.id,
								categoryIdx,
							});
						}
					});
				}

				// Поиск в подкатегориях
				if (category.subcategories)
				{
					category.subcategories.forEach((subcategory, subcategoryIdx) => {
						if (subcategory.knowledges)
						{
							subcategory.knowledges.forEach((knowledge) => {
								if (knowledge.checked === true) {
									result.subcategories.push({
										categoryId: category.id,
										subcategoryId: subcategory.id,
										knowledgeId: knowledge.id,
										categoryIdx,
										subcategoryIdx,
									});
								}
							});
						}
					});
				}
			});

			return result;
		},

		removeChecked()
		{
			this.categories.forEach((el) => {
				this.addChecked(el);
			});

			this.categories = JSON.parse(JSON.stringify(this.categories));
		},

		collectIds()
		{
			const data = this.findCheckedItems();

			let string = '';

			if (data.categories && data.categories.length)
				data.categories.forEach(el => string += `${el.knowledgeId}, `);

			if (data.subcategories && data.subcategories.length)
				data.subcategories.forEach(el => string += `${el.knowledgeId}, `);

			string = string.slice(0, -2);

			return string
		},

		async deleteChecked()
		{
			const data = this.findCheckedItems();
			const string = this.collectIds();

			await KnowledgeModule.destroyMany(string)
				.then(() => {
					Notify.success('Знания удалены');
					if (data.categories && data.categories.length)
					{
						data.categories.forEach(el => {
							const knowledgeIdx = this.categories[el.categoryIdx].knowledges.findIndex((knowledge) => knowledge.id === el.knowledgeId)
							this.categories[el.categoryIdx].knowledges.splice(knowledgeIdx, 1);
						});
					}
					if (data.subcategories && data.subcategories.length)
					{
						data.subcategories.forEach(el => {
							const knowledgeIdx = this.categories[el.categoryIdx].subcategories[el.subcategoryIdx].knowledges.findIndex((knowledge) => knowledge.id === el.knowledgeId)
							this.categories[el.categoryIdx].subcategories[el.subcategoryIdx].knowledges.splice(knowledgeIdx, 1);
						});
					}
					this.categories = JSON.parse(JSON.stringify(this.categories));
					this.closeDeleteContext()
				})
				.catch(() => this.closeDeleteContext())
		},

		openDeleteContext()
		{
			const data = this.findCheckedItems();

			if(!data.categories.length && !data.subcategories.length)
			{
				Notify.success('Нечего удалять');
				return;
			}

			if (data.categories.length)
			{
				data.categories.forEach(el => {
					const knowledgeIdx = this.categories[el.categoryIdx].knowledges.findIndex((knowledge) => knowledge.id === el.knowledgeId)
					this.deleteItemsTitles += this.categories[el.categoryIdx].knowledges[knowledgeIdx].title + ', ';
				});
			}

			if (data.subcategories && data.subcategories.length)
			{
				data.subcategories.forEach(el => {
					const knowledgeIdx = this.categories[el.categoryIdx].subcategories[el.subcategoryIdx].knowledges.findIndex((knowledge) => knowledge.id === el.knowledgeId)
					this.deleteItemsTitles += this.categories[el.categoryIdx].subcategories[el.subcategoryIdx].knowledges[knowledgeIdx].title + ', ';
				});
			}
			this.deleteItemsLength = data.categories.length + data.subcategories.length;
			this.deleteItemsTitles = this.deleteItemsTitles.slice(0, -2);

			this.showDeleteContext = true;
		},

		closeDeleteContext()
		{
			this.showDeleteContext = false;
			this.deleteItemsTitles = '';
			this.deleteItemsLength = null;
		},

		async checkItem(value)
		{
			// finding indexes
			const categoryIdx = this.categories.findIndex((el) => el.id === value.category);
			let subcategoryIdx = null;
			let knowledgeIdx = null;

			if (value.subcategoryId)
				subcategoryIdx = this.categories[categoryIdx].subcategories.findIndex((el) => el.id === value.subcategoryId);

			if (subcategoryIdx !== null)
				knowledgeIdx = this.categories[categoryIdx].subcategories[subcategoryIdx].knowledges.findIndex((el) => el.id === value.knowledgeId);
			else
				knowledgeIdx = this.categories[categoryIdx].knowledges.findIndex((el) => el.id === value.knowledgeId);

			// set checked
			if (subcategoryIdx !== null)
				this.categories[categoryIdx].subcategories[subcategoryIdx].knowledges[knowledgeIdx].checked = !this.categories[categoryIdx].subcategories[subcategoryIdx].knowledges[knowledgeIdx].checked;
			else
				this.categories[categoryIdx].knowledges[knowledgeIdx].checked = !this.categories[categoryIdx].knowledges[knowledgeIdx].checked;

			this.categories = JSON.parse(JSON.stringify(this.categories));
		},

		async mergeToSubcategory()
		{
			const data = this.findCheckedItems();

			let parentId = '';

			if (data.categories.length)
				parentId = data.categories[0].categoryId;
			else
				parentId = data.subcategories[0].categoryId;

			const result = await ApiClient.subCategories.getSubCategories(parentId);

			this.subcategoriesArray = result.data;
			this.showCreateSub = true;
		},

		async fetchRole(filter = '')
		{
			this.isLoading = true;

			await KnowledgeModule.fetchKnowledges(`?filter[role]=${this.$route.params.id}${filter}`)
				.then((res) => {
					if (this.$route.params.id === '0')
					{
						this.categories = res.data.data.for_all.categories;
						this.categories.forEach((el) => {
							this.addChecked(el);
						})
						this.activeRoleName = 'Для всех';
						this.isLoading = false;
						return;
					}
					this.categories  = res.data.data[this.$route.params.id].items.categories;
					this.categories.forEach((el) => {
						this.addChecked(el);
					})

					this.activeRoleName = res.data.data[this.$route.params.id].name;
					this.isLoading = false;
				})
				.catch(() => {
					Notify.error('Что-то пошло не так, попробуйте позже.');
				})
		},

		async fetchMarks()
		{
			await TagsModule.fetchTags()
				.then(() => {
					this.marks = TagsModule.tags.map((el) => {
						return {
							id: el.id,
							title: el.name,
							color: el.color,
							checked: false,
						};
					});
				});
		},

		// drag and drop

		getChildPayload (groupIndex, itemIndex)
		{
			return this.categories[groupIndex].knowledges[itemIndex];
		},

		onDrop({ groupIndex, value })
		{
			const { removedIndex, addedIndex, payload } = value;

			if (removedIndex === null && addedIndex === null)
				return;

			if (removedIndex !== null)
				this.categories[groupIndex].knowledges.splice(removedIndex, 1);

			if (addedIndex !== null)
			{
				this.categories[groupIndex].knowledges.splice(addedIndex, 0, payload);
				const categoryId = this.categories[groupIndex].id;
				const knowledgePayload = {
					id: payload.id,
					category_id: categoryId,
					subcategory_id: '0',
				}
				ApiClient.knowledge.updateKnowledge(knowledgePayload);
			}
		},

		onSubcategoryDrop({ groupIndex, subcategoryIndex, dropResult })
		{
			const { removedIndex, addedIndex, payload } = dropResult;

			if (removedIndex === null && addedIndex === null)
				return;

			if (removedIndex !== null)
				this.categories[groupIndex].subcategories[subcategoryIndex].knowledges.splice(removedIndex, 1);

			if (addedIndex !== null)
			{
				this.categories[groupIndex].subcategories[subcategoryIndex].knowledges.splice(addedIndex, 0, payload);
				const categoryId = this.categories[groupIndex].id;
				const subcategoryId = this.categories[groupIndex].subcategories[subcategoryIndex].id;

				const knowledgePayload = {
					id: payload.id,
					category_id: categoryId,
					subcategory_id: subcategoryId,
				}
				ApiClient.knowledge.updateKnowledge(knowledgePayload);
			}
		},

		// item functions

		deleteCategory(value)
		{
			const idx = this.categories.findIndex(el => el.id === value);

			ApiClient.categories.deleteCategory(value)
				.then(() => {
					this.categories.splice(idx, 1);
					Notify.success('Категория удалена');
				})
				.catch(() => Notify.error('Что-то пошло не так, попробуйте позже.'));
		},

		deleteItem({ categoryId, itemId })
		{
			const categoryIdx = this.categories.findIndex(el => el.id === categoryId);
			const knowledgeIdx = this.categories[categoryIdx].knowledges.findIndex(el => el.id === itemId);

			ApiClient.knowledge.deleteKnowledge(itemId).then(() => {
				Notify.success('Знание удалено');
				this.categories[categoryIdx].knowledges.splice(knowledgeIdx, 1);
			});
		},

		deleteSubcategory({ groupIndex, subcategoryIndex })
		{
			const id = this.categories[groupIndex].subcategories[subcategoryIndex].id;
			ApiClient.subCategories.deleteSubcategory(id).then(() => {
				Notify.success('Подкатегория удалена');
				this.categories[groupIndex].subcategories.splice(subcategoryIndex, 1);
			});
		},

		deleteSubcategoryItem({ groupIndex, subcategoryIndex, event })
		{
			const subcategoryIdx = this.categories[groupIndex].subcategories.findIndex(el => el.id === subcategoryIndex);
			const subcategoryItemIdx = this.categories[groupIndex].subcategories[subcategoryIdx].knowledges.findIndex(el => el.id === event);

			ApiClient.knowledge.deleteKnowledge(event).then(() => {
				Notify.success('Знание удалено');
				this.categories[groupIndex].subcategories[subcategoryIdx].knowledges.splice(subcategoryItemIdx, 1);
			});
		},

		editPinState({ id, state })
		{
			const idx = this.categories.findIndex(el => el.id === id);

			ApiClient.categories.updateCategory(id, { pinned: state })
				.then(() => {
					this.categories[idx].pinned = state;
					Notify.success(`Категория ${state ? 'закреплена' : 'откреплена'}`);
				});
		},

		updateCategoryName({ id, value })
		{
			const idx = this.categories.findIndex(el => el.id === id);

			ApiClient.categories.updateCategory(id, { name: value })
				.then(() => {
					this.categories[idx].name = value;
					Notify.success('Категория изменена');
				})
				.catch(() => Notify.success('Название категории должно быть уникальным'));
		},
	},

	async mounted()
	{
		await this.fetchRole();
		await this.fetchMarks();
	},
}
</script>

<style lang="scss" scoped>
	.documents-dashboard
	{
		padding: 30px 22px;
		max-width: 1440px;
		width: calc(100% - 68px);
		margin: 0 auto;
		padding-bottom: 100px;

		.delete-context-wrp
		{
			.delete-context__label
			{
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}
	}

	.documents-dashboard__delete-items-title
	{
		margin: 0;
		margin-bottom: 32px;
		font-size: 24px;
		font-weight: 400;
		line-height: 31.2px;
		text-align: center;
		color: #FFFFFFCC;
	}

	.documents-dashboard__delete-items-info
	{
		margin: 0;
		font-family: Museo Sans Cyrl;
		font-size: 14px;
		font-weight: 400;
		line-height: 16.8px;
		text-align: center;
		color: #FFFFFF80;
	}

	.documents-dashboard__item
	{
		position: relative;
		border-radius: 19px;
		margin: 0 20px 30px 0;
		background: #222222;

		&::before
		{
			content: '';
			position: absolute;
			z-index: -1;
			top: -10px;
			width: 98%;
			border-radius: 19px;
			height: 50px;
			background: #2C2C2C99;
			left: 50%;
			transform: translateX(-50%);
		}

		.documents-dashboard__item-subcategories-wr:last-of-type
		{
			padding-bottom: 20px;
		}
	}

	.documents-dashboard__item-subcategories-wr { padding: 30px 30px 0 30px; }

	.subcategory-popup
	{
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: #00000080;

		display: flex;
		align-items: center;
		justify-content: center;
	}

	.subcategory-popup__content
	{
		max-width: 377px;
		width: 100%;
		box-shadow: 0px 4px 40px 0px #000000B2;
		border-radius: 20px;
		overflow: hidden;
		background-color: #191919;
	}

	.subcategory-popup__content-title
	{
		margin: 30px 80px;
		font-family: Museo Sans Cyrl;
		font-size: 18px;
		font-weight: 400;
		line-height: 21.6px;
		color: white;
	}

	.subcategory-popup__content-items
	{
		padding: 20px;
		padding-top: 0;
		display: flex;
		flex-direction: column;
		gap: 20px;
		max-height: 300px;
		overflow-y: auto;

		&::-webkit-scrollbar { width: 4px; }
		&::-webkit-scrollbar-track { background: transparent; }
		&::-webkit-scrollbar-thumb {
			background-color: #3E3E3EB2;
			border-radius: 20px;
		}
	}

	.subcategory-popup__content-item
	{
		max-width: 100%;
		width: max-content;
		cursor: pointer;
		font-size: 16px;
		font-weight: 400;
		line-height: 19.2px;
		color: #FFFFFFB2;
		transition: all .2s ease;

		&:hover { color: white }
	}

	.subcategory-popup__content-button
	{
		cursor: pointer;
		color: #FFFFFF66;
		padding: 20px;
		background-color: #222222;
		display: flex;
		align-items: center;
		gap: 8px;
		font-size: 18px;
		font-weight: 300;
		line-height: 21.6px;
	}

	.subcategory-popup__content-empty
	{
		font-size: 16px;
		font-weight: 300;
		line-height: 19.2px;
		color: #FFFFFFB2;
	}
</style>